import {Directive} from 'vue'

const resizeObservers = {}

const setResizeObserver = (el: HTMLIonContentElement) => {
  const id = Math.round(Math.random() * 1000000)
  el.id = '' + id
  const modal = el.parentElement
  const toolbar = modal.querySelector('ion-toolbar')
  const content = el.querySelector(':first-child')
  resizeObservers[id] = new ResizeObserver(() => {
    modal.style.setProperty('--height', (content.scrollHeight + toolbar.scrollHeight) + 'px')
  })
  if (content) {
    resizeObservers[id].observe(content)
  }
}

const removeResizeObserver = (content: HTMLIonContentElement) => {
  resizeObservers[+content.id]?.disconnect()
  delete resizeObservers[+content.id]
}


export const modalResize: Directive = {
  mounted: (el: HTMLIonContentElement) => {
    if(window.innerWidth > 768){
      setResizeObserver(el)
    }
  },
  unmounted: (el) => {
    removeResizeObserver(el)
  }
}
