import {AuthProvider} from '@/providers/auth.provider'
import {AuthSession} from '@wonder/entities'
import {defineStore} from 'pinia'
import {Ref, ref} from 'vue'

export const useAuthStore = defineStore('auth', () => {

  const authSession: Ref<AuthSession | null> = ref(null)

  const login = ({email, password}: { email: string, password: string }) => {
    return AuthProvider.login(email, password)
  }

  function logout(): Promise<void> {
    return AuthProvider.logout()
      .then(() => authSession.value = null)
  }

  function fetchSession(): Promise<AuthSession | null> {
    return AuthProvider.fetchSession()
      .then(session => {
        authSession.value = session
        return Promise.resolve(session)
      })
      .catch(() => authSession.value = null)
  }

  return {
    authSession,
    login,
    logout,
    fetchSession
  }
})
