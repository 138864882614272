"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Zone = void 0;
var Zone;
(function (Zone) {
    Zone["BUTTOCKS"] = "BUTTOCKS";
    Zone["ABDOMEN"] = "ABDOMEN";
    Zone["ARMS"] = "ARMS";
    Zone["QUADRICEPS"] = "QUADRICEPS";
    Zone["OBLIQUE"] = "OBLIQUE";
    Zone["FEMORIS"] = "FEMORIS";
    Zone["LUMBAR"] = "LUMBAR";
    Zone["DORSAL"] = "DORSAL";
    Zone["PECTORAL"] = "PECTORAL";
    Zone["TRAPEZOID"] = "TRAPEZOID";
    Zone["FREE"] = "FREE";
})(Zone = exports.Zone || (exports.Zone = {}));
