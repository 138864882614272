export default {
  en: {
    /*
     * COMMON
     */
    'all': 'All',
    'welcome': 'Welcome',
    'edit': 'Edit',
    'create': 'Create',
    'cancel': 'Cancel',
    'save': 'Save',
    'accept': 'Accept',
    'delete': 'Delete',
    'name': 'Name',
    'error.isUnique': '{0} is already being used',
    'logo': 'Logo',
    'add': 'Add',
    'success': 'Success!',
    'error': 'Error!',
    'created': 'Created',
    'search': 'Search',
    'payments': 'Payments',
    'id': 'Id',
    'now': 'Now',

    /*
     * ACCOUNT / AUTH
     */
    'account': 'Account',
    'accounts': 'Accounts',
    'login': 'Login',
    'logout': 'Close session',
    'email': 'Email',
    'password': 'Password',
    'new-password': 'New password',
    'login-error': 'Invalid email or password',
    'invalid-password': 'The password is not correct',
    'password-confirmation': 'Password confirmation',
    'password-confirmation-message': 'Please enter your current password to perform this action',
    'role': 'Role',
    'roles': 'Roles',
    'account-created': 'Account created',
    'account-updated': 'Account updated',
    'account-removed': 'Account deleted',

    /*
     * ADMINS
     */
    'admins': 'Admins',
    'admin': 'Admin',
    'admin-created': 'Admin created',
    'admin-updated': 'Admin updated',
    'admin-removed': 'Admin deleted',

    /*
     * OWNERS
     */
    'franchisees': 'Franchisees',
    'owners': 'Owners',
    'owner': 'Owner',
    'owner-created': 'Owner created',
    'owner-updated': 'Owner updated',
    'owner-removed': 'Owner deleted',
    'no-owners': 'There are no owners yet',

    /*
     * CLINICS
     */
    'clinics': 'Clinics',
    'clinic': 'Clinic',
    'clinic-created': 'Clinic created',
    'clinic-updated': 'Clinic updated',
    'clinic-removed': 'Clinic deleted',
    'sessions': 'Sessions',
    'free-test': 'Free test',
    'devices': 'Devices',
    'device': 'Device',
    'device-updated': 'Device updated',
    'select-month': 'Select month',
    'session': 'Session | Sessions',
    'no-clinics': 'There are no clinics yet',
    'no-sessions': 'There are no sessions yet',
    'no-devices': 'There are no device yet',

    /**
     * APPS
     */
    'apps': 'Apps',
    'app': 'App',
    'no-apps': 'There are no apps yet',
    'app-created': 'App created',
    'app-updated': 'App updated',
    'app-removed': 'App deleted',
    'logo-required': 'Logo is required',

    /**
     * FLAVORS
     */
    'no-flavors': 'There are no flavors yet',
    'flavor': 'Flavor',
    'flavors': 'Flavors',
    'color': 'Color',
    'home-picture': 'Home Picture',
    'background-picture': 'Background Picture',
    'zones': 'Zones',
    'programs': 'Programs',
    'sequences': 'Sequences',
    'duration': 'Duration',
    'frequency': 'Frequency',
    'relax-frequency': 'Relax Freq.',
    'relax-increment': 'Relax Inc.',
    'contraction-time': 'Contr.',
    'relax-time': 'Relax',
    'pulse-widths': 'Pulse widths',
    'no-programs': 'No programs',
    'no-sequences': 'No sequences',
    'flavor-created': 'Flavor created',
    'flavor-updated': 'Flavor updated',
    'flavor-removed': 'Flavor deleted',
    'isTWC': 'The Wonder Clinic',
    'ppt': 'Pay per time',
    'isPayPerTime': 'Pay Per Time',
    'isControlled': 'Controlled',
    'isLockable': 'Lockable',
    'electromagnetic': 'Electromagnetic',
    'radiofrequency': 'Radio Frequency',
    'multiuser': 'Multiuser',
    'increment-intensity': 'Inc. Intensity',
    'isControllable': 'Controllable',

    /**
     * DISTRIBUTORS
     */
    'no-distributors': 'There are no distributors yet',
    'distributors': 'Distributors',
    'distributor': 'Distributor',
    'country': 'Country',
    'distributor-created': 'Distributor created',
    'distributor-updated': 'Distributor updated',
    'distributor-removed': 'Distributor deleted',

    /**
     * LICENSES
     */
    'licenses': 'Licenses',
    'license': 'License',
    'generate': 'Generate',
    'select-a-flavor': 'Select a flavor',
    'key': 'Key',
    'lock': 'Lock',
    'unlock': 'Unlock',
    'locked': 'Locked',
    'status': 'Status',
    'tax': 'Tax',
    'more-options': 'More options',
    'options': 'Options',

    /**
     * PRICES
     */
    'prices': 'Prices',
    'price': 'Price',
    'no-prices': 'No prices',

    /**
     * DISCOUNTS
     */
    'discounts': 'Discounts',
    'discount': 'Discount',
    'currency': 'Currency',
    'code': 'Code',
    'percentage': 'Percentage'
  }
}
