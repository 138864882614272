import {authGuard} from '@/router/auth.guard'
import {createRouter, createWebHistory} from '@ionic/vue-router'
import {Role} from '@wonder/entities'
import {RouteRecordRaw} from 'vue-router'

const routes: Array<RouteRecordRaw> = [{
  path: '/',
  redirect: ''
}, {
  path: '/home',
  name: 'Home',
  component: () => import('@/views/pages/HomePage.vue')
}, {
  path: '/licenses',
  name: 'Licences',
  component: () => import('@/views/pages/LicensesPage.vue'),
  meta: {
    roles: [Role.ADMIN]
  }
}, {
  path: '/clinics',
  name: 'Clinics',
  component: () => import('@/views/pages/ClinicsPage.vue'),
  meta: {
    roles: [Role.ADMIN, Role.FRANCHISEE]
  }
}, {
  path: '/clinics/:id',
  name: 'Clinic',
  component: () => import('@/views/pages/ClinicPage.vue'),
  meta: {
    roles: [Role.ADMIN, Role.FRANCHISEE]
  }
}, {
  path: '/devices',
  name: 'Devices',
  component: () => import('@/views/pages/DevicesPage.vue'),
  meta: {
    roles: [Role.OWNER, Role.DISTRIBUTOR]
  }
}, {
  path: '/devices/:id',
  name: 'Device',
  component: () => import('@/views/pages/DevicePage.vue'),
  meta: {
    roles: [Role.ADMIN, Role.OWNER, Role.FRANCHISEE, Role.DISTRIBUTOR]
  }
}, {
  path: '/flavors',
  name: 'Flavors',
  component: () => import('@/views/pages/FlavorsPage.vue'),
  meta: {
    roles: [Role.ADMIN]
  }
}, {
  path: '/apps',
  name: 'Apps',
  component: () => import('@/views/pages/AppsPage.vue'),
  meta: {
    roles: [Role.ADMIN]
  }
}, {
  path: '/accounts',
  name: 'Accounts',
  component: () => import('@/views/pages/AccountsPage.vue'),
  meta: {
    roles: [Role.ADMIN]
  }
}, {
  path: '/flavors/:id',
  name: 'FlavorEdition',
  component: () => import('@/views/pages/FlavorEditionPage.vue'),
  meta: {
    roles: [Role.ADMIN]
  }
}, {
  path: '/prices',
  name: 'Prices',
  component: () => import('@/views/pages/PricesPage.vue'),
  meta: {
    roles: [Role.ADMIN]
  }
}, {
  path: '/discounts',
  name: 'Discounts',
  component: () => import('@/views/pages/DiscountsPage.vue'),
  meta: {
    roles: [Role.ADMIN]
  }
}, {
  path: '/login',
  name: 'Login',
  component: () => import('@/views/pages/LoginPage.vue')
}, {
  path: '/account',
  name: 'Account',
  component: () => import('@/views/pages/AccountPage.vue')
}]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(authGuard)

export default router
