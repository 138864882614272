import {toastController} from '@ionic/vue'
import {checkmarkCircleOutline, closeCircleOutline} from 'ionicons/icons'

const showToast = (message: string, color: 'success' | 'danger' = 'success') => {
  toastController.create({
    cssClass: 'font-medium',
    position: 'top',
    duration: 3000,
    buttons: [{
      text: 'Close',
      role: 'cancel'
    }],
    message,
    color,
    icon: color === 'success' ? checkmarkCircleOutline : color === 'danger' ? closeCircleOutline : undefined
  }).then((alert) => alert.present())
}

export default showToast
