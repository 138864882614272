import {createAnimation} from '@ionic/vue'

export const fade = (_, {enteringEl, leavingEl}: any) => {
  const enterAnimation = createAnimation()
    .addElement(enteringEl.querySelector('.ion-page > ion-content'))
    .duration(150)
    .fromTo('opacity', '0', '1')
  return createAnimation()
    .addElement(leavingEl.querySelector('.ion-page > ion-content'))
    .duration(150)
    .fromTo('opacity', '1', '0')
    .onFinish(() => enterAnimation.play())
}
