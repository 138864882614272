import showToast from '@/tools/showToast'
import {Http} from '@capacitor-community/http'
import {useAuthStore} from '@/stores/auth.store'

const serverURl = process.env.VUE_APP_SERVER_URL

const buildUrl = (...parts: string[]) => parts.map(part => part
  .replace(/\/$/, '')
  .replace(/^\//, ''))
  .join('/')

const showError = () => showToast('Something went wrong, if the problem persists contact the system admin', 'danger')

const request = ({
                   method,
                   data,
                   base,
                   path,
                   params,
                   headers,
                 }: { method: string, data?: any, base: string, path: string, params?: Record<string, any>, headers?: Record<string, string> }) =>
  Http.request({
    method: method.toUpperCase(),
    data,
    params: params && Object.fromEntries(Object.entries(params).filter(([key, value]) => ![null, undefined].includes(value))),
    url: buildUrl(serverURl || '/api', base, path),
    headers: {
      'content-type': data ? 'application/json' : '',
      ...(headers || {})
    },
    webFetchExtra: {
      credentials: 'include'
    }
  }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response.data)
    } else {
      return Promise.reject(response)
    }
  }).catch((response) => {
    if (!response.status || response.status === 500) {
      showError()
      return Promise.reject()
    } else if (response.status === 401
      && !['/', '/login'].includes(window.location.pathname)
      && path !== '/session') {
      useAuthStore().authSession = null
      window.location.reload()
    } else {
      return Promise.reject(response.data)
    }
  })

export const useHttp = (base: string) => ({

  post(path: string, data?: any, headers?: Record<string, string>) {
    return request({method: 'post', data, base, path, headers})
  },

  get(path: string, params?: Record<string, any>, headers?: Record<string, string>) {
    return request({method: 'get', base, path, params, headers})
  },

  patch(path: string, data: any, headers?: Record<string, string>) {
    return request({method: 'patch', data, base, path, headers})
  },

  put(path: string, data: any, headers?: Record<string, string>) {
    return request({method: 'put', data, base, path, headers})
  },

  delete(path: string, headers?: Record<string, string>) {
    return request({method: 'delete', base, path, headers})
  }

})
