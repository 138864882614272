import clickOnFocus from '@/directives/click-on-focus'
import {modalResize} from '@/directives/modal-resize'
import {i18n} from '@/i18n'
import {isRole} from '@/tools/isRole'
import {IonicVue} from '@ionic/vue'

import '@ionic/vue/css/core.css'
import '@ionic/vue/css/display.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/float-elements.css'

import '@ionic/vue/css/normalize.css'

import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/typography.css'
import {createPinia} from 'pinia'
import {createApp} from 'vue'
import VueInputAutowidth from 'vue-input-autowidth'
import {Vue3Mq} from 'vue3-mq'
import App from './App.vue'

import 'vue3-easy-data-table/dist/style.css'
import './assets/style/main.css'

import router from './router'

const breakpoints = {
  'xs': '0',
  'sm': '640',
  'md': '768',
  'lg': '992',
  'xl': '1280',
  'xxl': '1536'
}

const pinia = createPinia()

const app = createApp(App)
  .use(IonicVue, {mode: 'ios'})
  .use(router)
  .use(pinia)
  .use(Vue3Mq, {preset: 'tailwind', breakpoints})
  .use(i18n)
  .use(VueInputAutowidth)
  .directive('modal-resize', modalResize)
  .directive('click-on-focus', clickOnFocus)

app.config.globalProperties.$is = isRole

router.isReady().then(() => {
  app.mount('#app')
})
