"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Role = void 0;
var Role;
(function (Role) {
    Role["ADMIN"] = "ADMIN";
    Role["OWNER"] = "OWNER";
    Role["FRANCHISEE"] = "FRANCHISEE";
    Role["STAFF"] = "STAFF";
    Role["CUSTOMER"] = "CUSTOMER";
    Role["DISTRIBUTOR"] = "DISTRIBUTOR";
})(Role = exports.Role || (exports.Role = {}));
