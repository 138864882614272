import {AuthSession, Role} from '@wonder/entities'

export const roleHome = (authSession: AuthSession) => {
  if (authSession.roles.includes(Role.ADMIN)) {
    return '/licenses'
  } else if (authSession.roles.includes(Role.OWNER) || authSession.roles.includes(Role.DISTRIBUTOR)) {
    return '/devices'
  } else if (authSession.roles.includes(Role.FRANCHISEE)) {
    return '/clinics'
  }
}