import {useHttp} from '@/tools/Http'
import {HttpResponse} from '@capacitor-community/http'
import {AuthSession} from '@wonder/entities'

const http = useHttp('auth')

export const AuthProvider = {
  login(email: string, password: string): Promise<void> {
    return http.post('/login', {email, password})
  },
  fetchSession(): Promise<AuthSession | null> {
    return http.get('/session')
      .catch((response: HttpResponse) => response.status === 401 ? null : Promise.reject(response))
  },
  logout(): Promise<void> {
    return http.post('/logout')
  }
}

