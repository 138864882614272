<template>
  <ion-app>
    <transition name="fade" mode="out-in">
      <ion-split-pane content-id="main" v-if="authSession">
        <!--  SIDE MENU START -->
        <ion-menu content-id="main" ref="menu">
          <ion-header class="overflow-visible">
            <ion-toolbar class="relative">
              <div class="text-center pt-1">
                <ion-button :router-link="home" router-direction="back" fill="clear" slot="start" class="mt-4 lg:mt-8"
                            @click="close">
                  <div class="border-b-2 border-primary pb-2" v-if="authSession.twc">
                    <TwcLogo class="h-7"/>
                  </div>
                  <img v-else src="../public/logo.png" alt="Wonder Logo" class="h-7 m-auto"/>
                </ion-button>
              </div>
            </ion-toolbar>
          </ion-header>
          <ion-content :fullscreen="true">
            <div class="flex flex-col justify-between h-full pt-4">
              <ion-list lines="none">
                <ion-item v-for="item in userMenu.pages" :key="item.path" button :router-link="item.path"
                          :router-direction="isActive(item.path) && 'back'"
                          :router-animation="!isActive(item.path) && fade" :class="{active: isActive(item.path)}"
                          :detail="false" @click="close" class="activatable">
                  <ion-icon :icon="item.icon" slot="start"/>
                  <ion-label class="uppercase antonio text-xl">{{ item.name }}</ion-label>
                </ion-item>

                <ion-accordion-group>
                  <ion-accordion v-for="category in userMenu.categories" :key="category.name">
                    <ion-item slot="header">
                      <ion-icon :icon="category.icon" slot="start"/>
                      <ion-label class="uppercase antonio text-xl">{{ category.name }}</ion-label>
                    </ion-item>

                    <div slot="content">
                      <ion-list lines="none">
                        <ion-item v-for="item in category.pages" :key="item.path" button :router-link="item.path"
                                  :router-direction="isActive(item.path) && 'back'"
                                  :router-animation="!isActive(item.path) && fade"
                                  :class="{active: isActive(item.path)}"
                                  :detail="false" @click="close" class="activatable">
                          <ion-icon :icon="item.icon" slot="start" class="ml-4"/>
                          <ion-label class="uppercase antonio text-xl">{{ item.name }}</ion-label>
                        </ion-item>
                      </ion-list>
                    </div>
                  </ion-accordion>

                </ion-accordion-group>
              </ion-list>
              <ion-item lines="none" button :detail="false" router-link="/account" :router-animation="fade"
                        @click="close" :class="{active: isActive('/account')}" class="activatable">
                <ion-icon :icon="personCircle" size="large" slot="start"/>
                <ion-label>
                  <span class="font-bold antonio uppercase">{{ authSession.name }}</span>
                  <p class="antonio">{{ authSession.email }}</p>
                </ion-label>
              </ion-item>
            </div>
          </ion-content>
        </ion-menu>
        <!--  SIDE MENU END -->

        <div id="main" class="w-full">

          <!-- PHONE/TABLET HEADER START -->
          <ion-header :translucent="true" v-if="mq.mdMinus">
            <ion-toolbar class="py-1">
              <ion-buttons slot="start" class="pl-1">
                <ion-menu-button color="light"/>
              </ion-buttons>
              <ion-title class="p-0">
                <ion-button fill="clear" class="logo-button -ml-3" :router-link="home" router-direction="back">
                  <div class="border-b-2 border-primary w-40 p-1" v-if="authSession.roles.includes('FRANCHISEE')">
                    <TwcLogo/>
                  </div>
                  <img v-else src="../public/logo.png" alt="Wonder Logo" class="h-6 m-auto"/>
                </ion-button>
              </ion-title>
            </ion-toolbar>
          </ion-header>
          <!-- PHONE/TABLET HEADER END -->

          <!-- CONTENT START -->
          <ion-router-outlet/>
          <!-- CONTENT END -->

        </div>
      </ion-split-pane>
      <ion-router-outlet v-else/>
    </transition>
  </ion-app>
</template>
<script lang="ts" setup>
import router from '@/router'
import {useAuthStore} from '@/stores/auth.store'
import {fade} from '@/tools/animations'
import {Menu} from '@/types/Menu'
import TwcLogo from '@/views/components/TwcLogo.vue'
import {
  IonAccordion,
  IonAccordionGroup,
  IonApp,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuButton,
  IonRouterOutlet,
  IonSplitPane,
  IonTitle,
  IonToolbar
} from '@ionic/vue'
import {AuthSession, Role} from '@wonder/entities'
import {
  cashOutline,
  colorPaletteOutline,
  cubeOutline,
  hardwareChipOutline,
  keyOutline,
  peopleOutline,
  personCircle,
  pricetagsOutline,
  storefrontOutline,
  timeOutline
} from 'ionicons/icons'
import {storeToRefs} from 'pinia'
import {computed, ComputedRef, Ref, ref, watch} from 'vue'
import {useI18n} from 'vue-i18n'
import {useRoute} from 'vue-router'
import {useMq} from 'vue3-mq'
import {roleHome} from '@/tools/roleHome'

const {t} = useI18n()
const route = useRoute()
const mq = useMq()

const authStore = useAuthStore()

const {authSession} = storeToRefs(authStore)

const compact = ref(false)

const home = computed(() => roleHome(authSession.value))

const menu: Ref<{ $el: HTMLIonMenuElement }> = ref()

const adminMenu: Ref<Menu> = ref({
  pages: [{
    icon: keyOutline,
    name: t('licenses'),
    path: '/licenses'
  }, {
    icon: storefrontOutline,
    name: t('clinics'),
    path: '/clinics'
  }, {
    icon: colorPaletteOutline,
    name: t('flavors'),
    path: '/flavors'
  }, {
    icon: cubeOutline,
    name: t('apps'),
    path: '/apps'
  }, {
    icon: peopleOutline,
    name: t('accounts'),
    path: '/accounts'
  }],
  categories: [{
    icon: timeOutline,
    name: t('ppt'),
    pages: [{
      name: 'prices',
      path: '/prices',
      icon: cashOutline
    }, {
      name: 'discounts',
      path: '/discounts',
      icon: pricetagsOutline
    }]
  }]
})

const franchiseeMenu: ComputedRef<Menu> = computed(() => ({
  pages: [{
    icon: storefrontOutline,
    name: t('clinics'),
    path: '/clinics'
  }]
}))

const ownerMenu: ComputedRef<Menu> = computed(() => ({
  pages: [{
    icon: hardwareChipOutline,
    name: t('devices'),
    path: '/devices'
  }]
}))

const userMenu: ComputedRef<Menu> = computed(() => {
  if (!authSession.value) return {pages: []}
  if (authSession.value.roles.includes(Role.ADMIN)) {
    return adminMenu.value
  } else if (authSession.value.roles.includes(Role.OWNER) || authSession.value.roles.includes(Role.FRANCHISEE) || authSession.value.roles.includes(Role.DISTRIBUTOR)) {
    const pages = []
    if (authSession.value.roles.includes(Role.OWNER) || authSession.value.roles.includes(Role.DISTRIBUTOR)) {
      pages.push(...ownerMenu.value.pages)
    }
    if (authSession.value.roles.includes(Role.FRANCHISEE)) {
      pages.push(...franchiseeMenu.value.pages)
    }
    return {pages}
  }
})

const close = () => {
  menu.value.$el.close()
}

const refresh = () => {
  router.go(0)
}

const isActive = (path: string) => new RegExp('^' + path + '/').test(route.path + '/')

watch(() => authSession.value, (session: AuthSession) => {
  if (session?.roles.includes(Role.FRANCHISEE)) {
    document.body.classList.add('twc')
  } else {
    document.body.classList.remove('twc')
  }
}, {immediate: true})
</script>
<style>
ion-split-pane {
  --side-max-width: 20%;
}

ion-split-pane > ion-menu > ion-header > ion-toolbar {
  --border-width: 0 !important;
}

ion-menu ion-content {
  --background: var(--ion-color-step-50)
}

ion-menu ion-list ion-item {
  --padding-top: 0.5rem;
  --padding-bottom: 0.5rem;
  --padding-start: 2rem;
  --inner-padding-start: 0.3rem;
}
</style>
