import {useAuthStore} from '@/stores/auth.store'
import {Role} from '@wonder/entities'
import {NavigationGuardWithThis} from 'vue-router'
import {roleHome} from '@/tools/roleHome'


export const authGuard: NavigationGuardWithThis<any> = async (to, from) => {
  const authStore = useAuthStore()

  const allowed = (roles: Role[]) => !to.meta.roles || (to.meta.roles as Role[]).some(role => roles.includes(role))

  const session = await authStore.fetchSession()

  if (to.path === '/login' && session) {
    return {path: '/', replace: true}
  } else if (!session && to.path !== '/login') {
    return {path: '/login', replace: true}
  } else if (session && (to.path === '/' || !allowed(session.roles))) {
    return {path: roleHome(session), replace: true}
  }
}
